import { PageProps } from 'gatsby';
import { FC } from 'react';

const NotFoundPage: FC<PageProps> = () => {
  return (
    <main>
      <title>Not found</title>
      Page not found
    </main>
  );
};

export default NotFoundPage;
